import React from 'react';
import { NavLink, Link } from 'react-router-dom';
import { Container, Button, Menu, Segment, Visibility, Responsive, Sidebar, Icon } from 'semantic-ui-react';
import styled from 'styled-components';

import logo from 'assets/logo-io.svg';
import logoInverted from 'assets/logo-io-inverted.svg';
import icon from 'assets/icon.svg';
import GitHubButton from 'react-github-btn';

const TopNav = styled(Menu)`
  &.ui.menu {
    background: #f4f0eb;
    border: 0;
    box-shadow: none;
    padding: 20px 0;
  }

  &.ui.menu.mobileNav {
    padding: 20px 20px 20px 10px;
    margin-bottom: 0;
  }

  &.ui.menu.fixed {
    background: #f4f0eb;
  }

  &.ui.menu .item {
    border-left: 0 !important;
    /* border-bottom: 2px solid transparent !important; */
    margin-left: 0 !important;
    text-transform: uppercase;
    font-weight: bold;
    letter-spacing: 1.33px;
    font-size: 14px;

    &::before {
      background: none;
    }

    &:hover {
      /* border-bottom: 2px solid #edf0e4 !important; */
    }

    .ui.button {
      border-bottom: 0 !important;
      margin-right: 0;
      text-transform: uppercase;
    }

    &:last-child {
      padding-right: 0;
      border-bottom: 0 !important;
    }
  }

  &.ui.menu a.item:hover {
    background: transparent;
  }

  &.ui.menu a.item.active {
    background: transparent;
    /* border-bottom: 2px solid #e1ead4 !important; */
  }

  &.ui.menu img.logo {
    padding-left: 0;
    width: 167px;
  }
`;

const MobileMenu = styled(Sidebar.Pushable)`
  .ui.menu.inverted {
    text-align: center;
    background: #1e1e1e;

    .item {
      text-transform: uppercase;
      font-weight: bold;
    }

    .ui.button {
      text-transform: uppercase;
    }
  }
`;

const FooterMenu = styled(TopNav)`
  &.ui.menu {
    background: transparent;
  }

  &.ui.menu .item,
  &.ui.menu .item:hover,
  &.ui.menu a.item.active {
    border: 0 !important;
  }

  &.ui.menu,
  &.ui.menu .right.menu {
    @media (max-width: 767px) {
      flex-direction: column;
      align-items: center;
      margin-left: 0 !important;
    }
  }

  &.ui.menu .right.menu {
    @media (max-width: 767px) {
      margin-top: 30px;

      div.item {
        padding-left: 0;
      }
    }
  }
`;

const Footer = styled(Segment)`
  &.ui.segment {
    margin: 0;
    padding: 44px 0 36px 0;
    border: 0;
    background: #1e1e1e;
    color: #eee;
    box-shadow: none;
  }
`;

const FooterSignature = styled(Footer)`
  &.ui.segment {
    padding: 16px 16px 12px 16px;
    border-top: 1px solid #2f2924;
    text-align: center;
    letter-spacing: 1px;
    font-size: 12px;

    .icon {
      height: 24px;
      padding-right: 10px;
    }

    span {
      vertical-align: top;
      margin-top: 1px;
    }
  }
`;

class DesktopContainer extends React.Component {
  state = {};
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { children } = this.props;
    const { fixed } = this.state;

    return (
      <Responsive minWidth={768}>
        <Visibility once={false}>
          <TopNav fixed="top">
            <Container>
              <Menu.Item as={Link} to="/">
                <img className="logo" src={`${logo}`} />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item as={NavLink} to="/features">
                  Features
                </Menu.Item>
                <Menu.Item as={NavLink} to="/docs">
                  Getting Started
                </Menu.Item>
                <Menu.Item as={NavLink} to="/enterprise">
                  Enterprise
                </Menu.Item>
                <Menu.Item as={NavLink} to="/about">
                  About
                </Menu.Item>
                <Menu.Item>
                  <Button
                    className="button primary basic"
                    target="_blank"
                    href="https://github.com/bedrockio/bedrock-core"
                  >
                    <i className="github icon"></i> Github
                  </Button>
                </Menu.Item>
              </Menu.Menu>
            </Container>
          </TopNav>
        </Visibility>

        {children}
      </Responsive>
    );
  }
}

class MobileContainer extends React.Component {
  state = {};

  handlePusherClick = () => {
    const { sidebarOpened } = this.state;

    if (sidebarOpened) this.setState({ sidebarOpened: false });
  };

  handleToggle = () => this.setState({ sidebarOpened: !this.state.sidebarOpened });

  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const { children } = this.props;
    const { sidebarOpened } = this.state;

    return (
      <Responsive maxWidth={767}>
        <MobileMenu>
          <Sidebar as={Menu} inverted vertical direction="top" animation="overlay" visible={sidebarOpened}>
            <Menu.Item style={{ textAlign: 'right' }}>
              <a onClick={this.handlePusherClick}>
                <Icon name="close" />
              </a>
            </Menu.Item>
            <Menu.Item as={NavLink} to="/features">
              Features
            </Menu.Item>
            <Menu.Item as={NavLink} to="/docs">
              Getting Started
            </Menu.Item>
            <Menu.Item as={NavLink} to="/enterprise">
              Enterprise
            </Menu.Item>
            <Menu.Item as={NavLink} to="/about">
              About
            </Menu.Item>
            <Menu.Item>
              <Button className="button primary basic" target="_blank" href="https://github.com/bedrockio/bedrock-core">
                <i className="github icon"></i> Github
              </Button>
            </Menu.Item>
          </Sidebar>

          <Sidebar.Pusher dimmed={sidebarOpened} onClick={this.handlePusherClick} style={{ minHeight: '100vh' }}>
            <TopNav className="mobileNav">
              <Menu.Item as={Link} to="/">
                <img className="logo" src={`${logo}`} />
              </Menu.Item>
              <Menu.Menu position="right">
                <Menu.Item onClick={this.handleToggle} style={{ paddingRight: '0' }}>
                  <Icon name="sidebar" style={{ fontSize: '1.5em' }} />
                </Menu.Item>
              </Menu.Menu>
            </TopNav>
            {children}
          </Sidebar.Pusher>
        </MobileMenu>
      </Responsive>
    );
  }
}

export default ({ children }) => (
  <div>
    <DesktopContainer>
      {children}
      <Footer vertical inverted>
        <Container>
          <FooterMenu inverted>
            <Menu.Item as={Link} to="/">
              <img className="logo" src={`${logoInverted}`} />
            </Menu.Item>
            <Menu.Menu position="right">
              <div style={{ float: 'left', marginTop: '14px', marginRight: '10px' }}>
                <GitHubButton
                  href="https://github.com/bedrockio/bedrock-core"
                  data-color-scheme="no-preference: dark; light: light; dark: dark;"
                  data-size="large"
                  aria-label="Star bedrockio/bedrock-core on GitHub"
                >
                  Star on Github
                </GitHubButton>
              </div>
              <Menu.Item as={NavLink} to="/features">
                Features
              </Menu.Item>
              <Menu.Item as={NavLink} to="/enterprise">
                Enterprise
              </Menu.Item>
              <Menu.Item as={NavLink} to="/docs">
                Docs
              </Menu.Item>
              <Menu.Item as={NavLink} to="/about">
                About
              </Menu.Item>
              <Menu.Item as={'a'} href="https://blog.bedrock.io">
                Blog
              </Menu.Item>
              <Menu.Item>
                <Button
                  className="button primary basic"
                  target="_blank"
                  href="https://github.com/bedrockio/bedrock-core"
                >
                  <i className="github icon"></i> Github
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </FooterMenu>
        </Container>
      </Footer>
      <FooterSignature vertical inverted>
        <Container>
          <img className="icon" src={`${icon}`} />
          <span>
            CREATED WITH <b>BEDROCK</b> — This application was built using Bedrock.
          </span>
        </Container>
      </FooterSignature>
    </DesktopContainer>
    <MobileContainer>
      {children}
      <Footer vertical inverted>
        <Container>
          <FooterMenu inverted>
            <Menu.Item as={Link} to="/">
              <img className="logo" src={`${logoInverted}`} />
            </Menu.Item>
            <Menu.Menu position="right">
              <Menu.Item as={NavLink} to="/features">
                Features
              </Menu.Item>
              <Menu.Item as={NavLink} to="/enterprise">
                Enterprise
              </Menu.Item>
              <Menu.Item as={NavLink} to="/docs">
                Docs
              </Menu.Item>
              <Menu.Item as={NavLink} to="/about">
                About
              </Menu.Item>
              <Menu.Item as={'a'} href="https://blog.bedrock.io">
                Blog
              </Menu.Item>
              <Menu.Item>
                <Button
                  className="button primary basic"
                  target="_blank"
                  href="https://github.com/bedrockio/bedrock-core"
                >
                  <i className="github icon"></i> Github
                </Button>
              </Menu.Item>
            </Menu.Menu>
          </FooterMenu>
        </Container>
      </Footer>
      <FooterSignature vertical inverted>
        <Container>
          <img className="icon" src={`${icon}`} />
          <span>
            CREATED WITH <b>BEDROCK</b>
            <br />
            This application was built using Bedrock.
          </span>
        </Container>
      </FooterSignature>
    </MobileContainer>
  </div>
);
