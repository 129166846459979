import React from 'react';
import { Container, Header, Grid, Table, Icon, Checkbox } from 'semantic-ui-react';
import styled from 'styled-components';
import { flatten } from 'lodash';

import LandingWrapper from 'components/LandingWrapper';
import capabilitiesWhitepaper from 'assets/downloads/Bedrock Capabilities.pdf';

const Section = styled(Container)`
  &.ui.container {
    padding: 60px 0 0 0;
    font-size: 16px;

    @media (max-width: 767px) {
      padding: 40px 0;
    }
  }
`;

const Hero = styled.div`
  padding: 140px 0 80px 0;
  background: #f4f0eb;
  background: linear-gradient(-4deg, #f9f8f5 30%, #f4f0eb 30%);
  margin-bottom: -40px;

  h1.ui.header {
    font-weight: 900;
    margin-top: 0;
  }

  h5.ui.header {
    font-weight: 400;
    text-transform: uppercase;
  }

  .ui.button {
    width: 48px;
    height: 48px;
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 0 0 80px 0;
    margin-bottom: -60px;

    h1,
    h5 {
      text-align: center;
    }
  }
`;

const FeatureRow = styled(Grid.Row)`
  &.row {
    padding-bottom: 68px !important;
    margin-bottom: 52px;
    border-bottom: 1px solid #ede9e4;

    @media (max-width: 767px) {
      padding-bottom: 40px !important;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: 0;

      @media (max-width: 767px) {
        padding-top: 40px !important;
      }
    }

    h2 {
      font-weight: 700;
    }

    .ui.image {
      width: 100%;

      @media (max-width: 767px) {
        width: 50%;
        margin: 40px auto;
      }
    }
  }
`;

const comparison = [
  {
    text: 'Infrastructure',
    items: [
      {
        text: 'Micro Services Architecture',
        items: ['Kubernetes-friendly containerization'],
      },
      {
        text: 'Scalable Infrastructure powered by Kubernetes',
        items: [
          'Google Cloud provisioning scripts',
          'Auto-scaling using Kubernetes',
          'Cluster security best practices',
          'Data store templates (Elasticsearch, MongoDB)',
        ],
      },
      {
        text: 'Deployment & Ops Automation',
        items: ['Deployment automation using Kubernetes', 'Playbooks for common and uncommon system interventions'],
      },
      {
        text: 'Backups & Monitoring',
        items: [
          'Backup System for incremental MongoDB backups',
          'Backup System for Bucket Storage backups',
          'Backup Alerts & Health Reports',
        ],
      },
    ],
  },
  {
    text: 'Data Backend',
    items: [
      {
        text: 'Up-to-date Backend Stack',
        items: [
          'Node.js using latest JS standards (Volta, Prettier, ESLint, etc.)',
          'Unit testing using Jest',
          'Error tracking using Sentry',
          'Support for performance tracing using Google Cloud Trace',
          'CI integration',
        ],
      },
      {
        text: 'Modern JSON APIs',
        items: [
          'Secure & Tested API Middleware',
          'Compatability with Cloudflare and Google Cloud',
          'Full unit test coverage of API calls',
        ],
      },
      {
        text: 'Security & Authentication Best Practices',
        items: ['Authentication using JWT tokens', 'Anti-brute force encryption best practices (BCRYPT)'],
      },
      {
        text: 'Ready to use APIs for Users, Authentication, Uploads & Generic CRUD',
        items: [
          'Authentication API with Invites',
          'User Management API',
          'File Uploads API with Local and Bucket Storage',
          'CRUD API Templates',
          'Status API for use with Pagerduty',
        ],
      },
      {
        text: 'Robust Data Capabilities',
        items: [
          'Automatic data fixture creation',
          'ORM using Mongoose',
          'MongoDB production best practices',
          'Dockerized Background Jobs',
        ],
      },
      {
        text: 'E-Mail & Push Delivery',
        items: ['Transactional Email Templating', 'E-Mail security using DKIM and SPF verification'],
      },
      {
        text: 'OpenAPI Based Documentation',
        items: ['API Guides with Examples and Getting Started', 'OpenAPI powered Documentation Portal'],
      },
    ],
  },
  {
    text: 'Dashboard Base',
    items: [
      {
        text: 'Up-to-date Frontend Stack',
        items: [
          'React using latest JS standards (Volta, Prettier, ESLint, ES6, etc.)',
          'Code organization best practices',
          'Unit testing using Jest',
        ],
      },
      {
        text: 'Production Ready',
        items: [
          'Production-grade asset bundling using Webpack and plugins',
          'Docker-friendly configuration',
          'Error tracking using Sentry',
        ],
      },
      {
        text: 'Single Page App & API-centric',
        items: [
          'Works with data backend API',
          'React Router with Server-side Middleware',
          'Middleware for protecting routes',
        ],
      },
      {
        text: 'Ready to use UIs for User Management, Authentication & CRUD',
        items: [
          'Authentication Flows (Signup, Login, Forgot password, etc.)',
          'User Management UI',
          'CRUD UI Templates',
        ],
      },
      {
        text: 'Designed for Development Speed',
        items: ['Code hotswapping', 'Pragmatic state management (no Redux)'],
      },
      {
        text: 'Rich Library of Components (Forms, Files, Views, etc.)',
        items: [
          'Rich Form Components (Date Selectors, etc.)',
          'File Uploads',
          'Helper libraries (Lodash, moment, etc.)',
          'Markdown support for documentation (with Github styles)',
        ],
      },
    ],
  },
  {
    text: 'Bedrock Enterprise',
    isEnterprise: true,
    items: [
      {
        text: 'Advanced Security & Authentication',
        items: [
          'Enterprise SSO Support (GSuite, Apple, Microsoft)',
          'SAML Support',
          'Advanced Login Options (Password expiry, complexity rules, attempts)',
          'User Settings (password change, notification settings, etc.)',
        ],
      },
      {
        text: 'Advanced Access Control',
        items: [
          'Granular permission configuration per endpoint',
          'Custom Role management',
          'Advanced API token management (expiry, permissions)',
          'Organization, Group and User level role assignment',
        ],
      },
      {
        text: 'Compliance Ready',
        items: [
          'Audit Trails',
          'Documentation for SysOps, DevOps and CodeOps processes',
          'Documentation and automation for compliance artifacts',
          'Compliance Standard Operating Procedures (SOPs)',
          'Compliance for HIPAA, FDA 21 CFR Part 11',
          'External audit report',
        ],
      },
      {
        text: 'Multi-Tenant Capabilities',
        items: [
          'Organization & Group Management',
          'Team Invites and onboarding',
          'Organization White Labeling Capabilities',
          'Organization Custom Domain configuration',
          'Organization SSL provisioning and routing',
        ],
      },
      {
        text: 'Big Data & Analytics',
        items: [
          'Geo Capabilities using Mapkit & Elasticsearch',
          'Analytics & Search Engine & API',
          'Historical data collection capabilities',
          'Visualization Components',
          'Interactive Analytics (time ranges, faceted filtering, etc.)',
          'Server-side PDF reporting',
          'CSV Export Capabilities',
          'CSV Interactive Imports',
        ],
      },
      {
        text: 'Third Party Integrations',
        items: [
          'Intergation with Stripe for payments',
          'Integration with Contentful CMS',
          'Integration with Intercom Chat/CRM',
        ],
      },
      {
        text: 'Internationalization',
        items: ['Multi-lingual UI Support', 'Advanced Media Capabilities (Image resize proxy, video encoders, etc.)'],
      },
    ],
  },
];

export default class Landing extends React.Component {
  state = {
    showDetailed: false,
  };
  render() {
    const { showDetailed } = this.state;
    return (
      <LandingWrapper>
        <Hero>
          <Container>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                <Header as="h5">Premium Features &amp; Services</Header>
                <Header as="h1">Bedrock Enterprise</Header>
              </Grid.Column>
            </Grid>
          </Container>
        </Hero>
        <Section>
          <Grid relaxed="very">
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={12} computer={12} mobile={16}>
                <p>
                  Bedrock Core is free and open source and will always remain so. However, we provide premium modules on
                  top of Bedrock Core that we call Bedrock Enterprise. These modules provide an additional security,
                  compliance and data capabilities. Download our{' '}
                  <a href={capabilitiesWhitepaper} target="_blank">
                    Bedrock.io Capabilities Whitepaper
                  </a>{' '}
                  for a full overview of security & reliability features.
                </p>
                <p>
                  Bedrock Enterprise includes a support SLA and a-la-carte consulting services for building your
                  production-grade platform.
                </p>
                <br />
                <p>
                  <a className="ui button primary" rel="external" target="_blank" href="mailto:info@bedrock.io">
                    Contact Us
                  </a>
                </p>
              </Grid.Column>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            </FeatureRow>
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={12} computer={12} mobile={16}>
                <Table definition size="small" style={{ border: 0, background: 'transparent' }}>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell style={{ boxShadow: 'none', pointerEvents: 'auto' }}>
                        <Checkbox
                          toggle
                          label="Show detailed features"
                          value={showDetailed}
                          onClick={(e, { checked }) => {
                            this.setState({ showDetailed: checked });
                          }}
                        />
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ background: 'transparent' }} textAlign="center">
                        Bedrock Core
                      </Table.HeaderCell>
                      <Table.HeaderCell style={{ background: 'transparent' }} textAlign="center">
                        Bedrock Enterprise
                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Header>
                  <Table.Body>
                    <Table.Row>
                      <Table.Cell>
                        <strong>Price</strong>
                      </Table.Cell>
                      <Table.Cell textAlign="center">Free Open Source</Table.Cell>
                      <Table.Cell textAlign="center">Contact Us</Table.Cell>
                    </Table.Row>
                    {flatten(
                      comparison.map((section) => {
                        const rows = [];
                        rows.push(
                          <Table.Row key={section.text}>
                            <Table.Cell>{section.text}</Table.Cell>
                            <Table.Cell></Table.Cell>
                            <Table.Cell></Table.Cell>
                          </Table.Row>
                        );
                        section.items.forEach((item) => {
                          rows.push(
                            <Table.Row key={item.text}>
                              <Table.Cell style={{ fontWeight: 'normal' }}>&nbsp;&nbsp;{item.text}</Table.Cell>
                              <Table.Cell textAlign="center">
                                {!section.isEnterprise && <Icon color="olive" name="checkmark" />}
                              </Table.Cell>
                              <Table.Cell textAlign="center">
                                <Icon color="olive" name="checkmark" />
                              </Table.Cell>
                            </Table.Row>
                          );
                          if (showDetailed) {
                            item.items.forEach((subItem) => {
                              rows.push(
                                <Table.Row key={subItem}>
                                  <Table.Cell style={{ fontWeight: 'normal', fontStyle: 'italic' }}>
                                    &nbsp;&nbsp;&nbsp;&nbsp;{subItem}
                                  </Table.Cell>
                                  <Table.Cell textAlign="center">
                                    {!section.isEnterprise && <Icon color="olive" name="checkmark" />}
                                  </Table.Cell>
                                  <Table.Cell textAlign="center">
                                    <Icon color="olive" name="checkmark" />
                                  </Table.Cell>
                                </Table.Row>
                              );
                            });
                          }
                        });
                        return rows;
                      })
                    )}
                  </Table.Body>
                </Table>
              </Grid.Column>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            </FeatureRow>
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={12} computer={12} mobile={16} textAlign="center">
                <Header as="h2">Want to chat about your project?</Header>
                <br />
                <p>
                  <a className="ui button primary" rel="external" target="_blank" href="mailto:info@bedrock.io">
                    Contact Us
                  </a>
                </p>
              </Grid.Column>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            </FeatureRow>
          </Grid>
        </Section>
      </LandingWrapper>
    );
  }
}
