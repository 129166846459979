import React from 'react';
import { NavLink } from 'react-router-dom';
import { Grid, Image, Button, Header, Container, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import LandingWrapper from 'components/LandingWrapper';

import icon from 'assets/icon.svg';
import block from 'assets/block.svg';
import iconDataCentric from 'assets/icon-data-centric.svg';
import iconHorizontallyScalable from 'assets/icon-horizontally-scalable.svg';
import iconSecureCompliant from 'assets/icon-secure-compliant.svg';
import iconModernStack from 'assets/icon-modern-stack.svg';
import iconElasticSearch from 'assets/technologies/elasticsearch-inverted.svg';
import iconKubernetes from 'assets/technologies/kubernetes-inverted.svg';
import iconMongoDB from 'assets/technologies/mongodb-inverted.svg';
import iconNodeJS from 'assets/technologies/nodejs-inverted.svg';
import iconReact from 'assets/technologies/react.svg';

const Hero = styled.div`
  padding: 140px 0 60px 0;
  background: #f4f0eb;
  background: linear-gradient(-8deg, #1e1e1e 30%, #f4f0eb 30%);
  margin-bottom: -40px;

  h1.ui.header {
    font-weight: 900;
    margin-top: 0;
  }

  h5.ui.header {
    font-weight: 400;
    text-transform: uppercase;
  }

  .ui.button {
    width: 36px;
    height: 36px;
    padding: 0;
  }

  img.ui.image {
    max-width: 100%;
    width: 100%;
  }

  @media (max-width: 767px) {
    padding: 0 0 80px 0;
    margin-bottom: -80px;

    h1,
    h5 {
      text-align: center;
    }

    img.ui.image {
      width: 50%;
      margin: -36px auto 0 auto;
    }
  }
`;

const Section = styled(Container)`
  padding: 40px 0 80px 0;

  @media (max-width: 767px) {
    padding: 0 0 40px 0;

    p.cta {
      text-align: center;
    }
  }
`;

const SectionInverted = styled(Container)`
  padding: 80px 0 40px 0;

  h2 {
    color: #fff !important;
    padding-bottom: 30px !important;
  }

  img.ui.image {
    width: 90%;
  }

  @media (max-width: 767px) {
    padding: 0 0 10px 0;

    .row {
      padding: 0 !important;
    }

    img.ui.image {
      width: 70%;
      margin-bottom: 40px;
    }
  }
`;

const FeatureHeader = styled(Header)`
  font-weight: 700;
`;

const InvertedBackground = styled.div`
  background: #1e1e1e;
`;

const FrameSection = styled(SectionInverted)`
  padding: 40px 0 60px 0;

  @media (max-width: 767px) {
    padding: 0 0 30px 0;
  }
`;

const FrameContainer = styled.div`
  position: relative;
  overflow: hidden;
  width: 100%;
  padding-top: 62.5%; /* 16:10 Aspect Ratio (divide 10 by 16 = 0.625) */

  .intro-video {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
  }
`;

export default class Landing extends React.Component {
  render() {
    return (
      <LandingWrapper>
        <Hero>
          <Container>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={6} computer={6} verticalAlign="middle">
                <Header as="h5">Open Source Platform Template</Header>
                <Header as="h1">The Mongo, Node &amp; React Stack</Header>
              </Grid.Column>
              <Grid.Column tablet={1} computer={1} mobile={16}></Grid.Column>
              <Grid.Column tablet={5} computer={5} mobile={16} verticalAlign="middle">
                <Image src={block} />
              </Grid.Column>
            </Grid>
          </Container>
        </Hero>
        <Divider hidden />

        <InvertedBackground>
          <FrameSection>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} verticalAlign="middle">
                <FrameContainer>
                  <iframe
                    src="https://player.vimeo.com/video/443474352"
                    frameborder="0"
                    allow="autoplay; fullscreen"
                    allowfullscreen
                    className="intro-video"
                  />
                </FrameContainer>
              </Grid.Column>
            </Grid>
          </FrameSection>
        </InvertedBackground>

        <Section>
          <Grid>
            <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            <Grid.Column mobile={16} tablet={6} computer={6} verticalAlign="middle">
              <Image height="48" src={icon} />
              <Header as="h2">Build Better Solutions, Faster.</Header>
              <p>
                Traditional monolithic web frameworks such as RubyOnRails &amp; Django have become ineffective at
                building modern solutions. Bedrock.io is a battle-tested collection of components, automation and
                patterns that allow you to rapidly build modern software solutions. Bedrock.io ties together MongoDB,
                Node &amp; React.
              </p>
              <br />
              <p className="cta">
                <Button as={NavLink} to="/features" primary>
                  Learn More
                </Button>
              </p>
            </Grid.Column>

            <Grid.Column tablet={7} computer={7} mobile={16} verticalAlign="middle">
              <Grid padded>
                <Grid.Row columns={2} textAlign="center" style={{ marginBottom: '24px' }}>
                  <Grid.Column>
                    <Image height="100" src={iconDataCentric} centered />
                    <FeatureHeader as="h3">Data-Centric</FeatureHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <Image height="100" src={iconModernStack} centered />
                    <FeatureHeader as="h3">Modern Stack</FeatureHeader>
                  </Grid.Column>
                </Grid.Row>
                <Grid.Row columns={2} textAlign="center">
                  <Grid.Column>
                    <Image height="100" src={iconSecureCompliant} centered />
                    <FeatureHeader as="h3">Secure & Compliant</FeatureHeader>
                  </Grid.Column>
                  <Grid.Column>
                    <Image height="100" src={iconHorizontallyScalable} centered />
                    <FeatureHeader as="h3">Horizontally Scalable</FeatureHeader>
                  </Grid.Column>
                </Grid.Row>
              </Grid>
            </Grid.Column>
          </Grid>
        </Section>
        <InvertedBackground>
          <SectionInverted>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} verticalAlign="middle">
                <Header as="h2" textAlign="center">
                  Technologies we built on
                </Header>
                <Grid padded>
                  <Grid.Row textAlign="center" centered>
                    <Grid.Column mobile={16} tablet={5} computer={5} verticalAlign="middle">
                      <Image src={iconKubernetes} centered />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5} verticalAlign="middle">
                      <Image src={iconElasticSearch} centered />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5} verticalAlign="middle">
                      <Image src={iconMongoDB} centered />
                    </Grid.Column>
                  </Grid.Row>
                  <Grid.Row textAlign="center" centered>
                    <Grid.Column mobile={16} tablet={5} computer={5} verticalAlign="middle">
                      <Image height="60" src={iconNodeJS} centered />
                    </Grid.Column>
                    <Grid.Column mobile={16} tablet={5} computer={5} verticalAlign="middle">
                      <Image height="80" src={iconReact} centered />
                    </Grid.Column>
                  </Grid.Row>
                </Grid>
              </Grid.Column>
            </Grid>
          </SectionInverted>
        </InvertedBackground>
      </LandingWrapper>
    );
  }
}
