import React from 'react';
import { Container, Header, Grid, Divider, Segment, Card } from 'semantic-ui-react';
import styled from 'styled-components';

import capabilitiesWhitepaper from 'assets/downloads/Bedrock Capabilities.pdf';
import LandingWrapper from 'components/LandingWrapper';
import GitHubButton from 'react-github-btn';

const Section = styled(Container)`
  &.ui.container {
    padding: 60px 0 20px 0;
    font-size: 16px;

    @media (max-width: 767px) {
      padding: 40px 0;
    }
  }
`;

const Hero = styled.div`
  padding: 140px 0 80px 0;
  background: #f4f0eb;
  background: linear-gradient(-4deg, #f9f8f5 30%, #f4f0eb 30%);
  margin-bottom: -40px;

  h1.ui.header {
    font-weight: 900;
    margin-top: 0;
  }

  h5.ui.header {
    font-weight: 400;
    text-transform: uppercase;
  }

  .ui.button {
    width: 48px;
    height: 48px;
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 0 0 80px 0;
    margin-bottom: -60px;

    h1,
    h5 {
      text-align: center;
    }
  }
`;

const FeatureRow = styled(Grid.Row)`
  &.row {
    padding-bottom: 68px !important;
    margin-bottom: 52px;
    border-bottom: 1px solid #ede9e4;

    @media (max-width: 767px) {
      padding-bottom: 40px !important;
      margin-bottom: 0;
    }

    &:last-child {
      border-bottom: 0;
    }

    h2 {
      font-weight: 700;

      @media (max-width: 767px) {
        padding-top: 20px;
      }
    }

    .ui.image {
      width: 100%;

      @media (max-width: 767px) {
        width: 50%;
        margin: 40px auto;
      }
    }

    .segment.inverted {
      font-family: SFMono-Regular, Consolas, Liberation Mono, Menlo, monospace;
      padding: 1em 1.2em;
      word-break: break-all;

      @media (max-width: 767px) {
        margin-top: 20px;
      }
    }
  }
`;

const CardStyled = styled(Card)`
  &.ui.card {
    width: 30%;
    text-decoration: none;

    @media (max-width: 767px) {
      width: 100%;
    }

    .content {
      padding: 1.5em;

      @media (max-width: 767px) {
        padding: 1.2em;
      }
    }

    .description {
      margin-top: 0 !important;
      font-weight: bold;
    }
  }
`;

export default class Landing extends React.Component {
  render() {
    return (
      <LandingWrapper>
        <Hero>
          <Container>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                <Header as="h5">From Zero to Production</Header>
                <Header as="h1">Getting Started</Header>
              </Grid.Column>
            </Grid>
          </Container>
        </Hero>
        <Section>
          <Grid relaxed="very">
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={7} computer={7} mobile={16}>
                <p>Run the command on the right in your terminal to create a new Bedrock project.</p>
                <p>
                  The command will give you instructions on how to run the stack with a single Docker compose command.
                </p>
                <Divider hidden />

                <GitHubButton
                  href="https://github.com/bedrockio/bedrock-core"
                  data-size="large"
                  data-show-count="false"
                  aria-label="Star bedrockio/bedrock-core on GitHub"
                >
                  Star on Github
                </GitHubButton>
              </Grid.Column>
              <Grid.Column tablet={7} computer={7} mobile={16} verticalAlign="middle">
                <Segment inverted>curl -s https://install.bedrock.io | bash</Segment>
              </Grid.Column>
            </FeatureRow>
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={12} computer={12} mobile={16}>
                <Header as="h2">Further Reading</Header>
                <br />
                <Card.Group>
                  <CardStyled
                    href="https://github.com/bedrockio/bedrock-core"
                    target="_blank"
                    header="Github Repo"
                    description="Core Mono Repo"
                  />
                  <CardStyled
                    href="https://blog.bedrock.io"
                    target="_blank"
                    header="Articles &amp; Guides"
                    description="Bedrock Blog"
                  />
                  <CardStyled
                    href="https://vimeo.com/bedrockio"
                    target="_blank"
                    header="Screencasts"
                    description="Bedrock Core"
                  />
                  <CardStyled
                    href="https://github.com/bedrockio/bedrock-core/tree/master/services/api"
                    target="_blank"
                    header="API Readme"
                    description="Bedrock Core"
                  />
                  <CardStyled
                    href="https://github.com/bedrockio/bedrock-core/tree/master/services/web"
                    target="_blank"
                    header="Web Readme"
                    description="Bedrock Core"
                  />
                  <CardStyled
                    href="https://github.com/bedrockio/bedrock-core/tree/master/services/web/src/components#bedrock-web-components"
                    target="_blank"
                    header="Web Components"
                    description="Bedrock Core"
                  />
                  <CardStyled
                    href="https://github.com/bedrockio/bedrock-core/tree/master/services/web/src/utils#bedrock-web-utils"
                    target="_blank"
                    header="Web Helpers"
                    description="Bedrock Core"
                  />
                  <CardStyled
                    href={capabilitiesWhitepaper}
                    target="_blank"
                    header="Bedrock"
                    description="Capabilities Whitepaper"
                  />
                </Card.Group>
              </Grid.Column>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            </FeatureRow>
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={12} computer={12} mobile={16}>
                <Header as="h2" id="community">
                  Community &amp; Support
                </Header>
                <br />
                <Card.Group>
                  <CardStyled
                    href="https://join.slack.com/t/bedrockio/shared_invite/zt-gc2e5gwn-mpNGwaijs1ksKYtkExCZtQ"
                    target="_blank"
                    ref="external"
                    header="Slack Chat"
                    description="Bedrock Community"
                  />
                  <CardStyled
                    href="https://github.com/bedrockio/bedrock-core/issues"
                    target="_blank"
                    ref="external"
                    header="Issues"
                    description="GH Issue Tracker"
                  />
                  <CardStyled
                    href="https://stackoverflow.com/questions/tagged/bedrock"
                    target="_blank"
                    ref="external"
                    header="Usage Questions"
                    description="Stackoverflow #bedrock"
                  />
                  <CardStyled
                    href="mailto:info@bedrock.io"
                    target="_blank"
                    ref="external"
                    header="Contact Us"
                    description="info@bedrock.io"
                  />
                  <CardStyled
                    href="https://blog.bedrock.io"
                    target="_blank"
                    header="Blog"
                    description="Articles &amp; Guides"
                  />
                </Card.Group>
              </Grid.Column>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            </FeatureRow>
          </Grid>
        </Section>
      </LandingWrapper>
    );
  }
}
