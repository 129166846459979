import React from 'react';
import { Container, Header, Grid, Card, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import LandingWrapper from 'components/LandingWrapper';

import brandingAssets from 'assets/downloads/bedrock-branding-assets.zip';

const Section = styled(Container)`
  &.ui.container {
    padding: 60px 0 120px 0;
    font-size: 16px;

    @media (max-width: 767px) {
      padding: 40px 0;
    }
  }
`;

const Hero = styled.div`
  padding: 140px 0 80px 0;
  background: #f4f0eb;
  background: linear-gradient(-4deg, #f9f8f5 30%, #f4f0eb 30%);
  margin-bottom: -40px;

  h1.ui.header {
    font-weight: 900;
    margin-top: 0;
  }

  h5.ui.header {
    font-weight: 400;
    text-transform: uppercase;
  }

  .ui.button {
    width: 48px;
    height: 48px;
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 0 0 80px 0;
    margin-bottom: -60px;

    h1,
    h5 {
      text-align: center;
    }
  }
`;

const CardStyled = styled(Card)`
  &.ui.card {
    width: 30%;
    text-decoration: none;

    @media (max-width: 767px) {
      width: 100%;
    }

    .content {
      padding: 1.5em;

      @media (max-width: 767px) {
        padding: 1.2em;
      }
    }

    .description {
      margin-top: 0 !important;
      font-weight: bold;
    }
  }
`;

export default class Landing extends React.Component {
  render() {
    return (
      <LandingWrapper>
        <Hero>
          <Container>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                <Header as="h5">A Platform Approach</Header>
                <Header as="h1">About</Header>
              </Grid.Column>
            </Grid>
          </Container>
        </Hero>
        <Section>
          <Grid relaxed="very">
            <Grid.Row>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={12} computer={12} verticalAlign="middle">
                <p>
                  <a className="ui button primary" href="/docs#community">
                    Contact Us
                  </a>
                  <Divider hidden />
                </p>
                <p>
                  Bedrock.io was founded by a group of experienced software engineers. Bedrock.io started out as an
                  opinionated template to keep up with the quickly changing Javascript landscape, mainly focused on
                  Single Page Apps and Node.js and the many frameworks du jour that came along.
                </p>
                <p>
                  Over time, more and more capabilities, practices and processes have been added to tackle production
                  engineering challenges such as data analytics, fault tolerance, security and compliance. We’ve
                  deployed many variations of Bedrock.io into production across a variety of industries: Healthcare,
                  Mobility, Retail, E-Commerce, etc. Bedrock.io is our attempt to capture some of those experiences into
                  a repository of code, patterns, practices and processes.
                </p>
                <br />

                <Header as="h2">Philosophy</Header>
                <p>
                  We believe in a platform approach to your business. This means your long term value and business goals
                  are distilled into your architecture and data capabilities. APIs are central in enabling new
                  applications and solutions. Creating a solid API foundation allows you to tack quickly as the world
                  around you changes. Bedrock.io allows you to get new applications and services to market rapidly.
                </p>
                <p>
                  Compartmentalizing your API and sub systems into well-tested sub systems allows you to develop faster
                  and make use of scalable modern cloud infrastructure. We think of this as a Micro-Services
                  Architecture, but we take “Micro” with a grain of salt. We like to use the right tool for the right
                  job - this is another benefit of isolating sub systems in simple JSON APIs. For example, for Machine
                  Learning we like to use Python and its plethora of ML libraries. For Data APIs we like to use Node.js
                  because it’s asynchronous nature and native http/JSON support make it very suitable for fast data
                  interchange.
                </p>
                <p>
                  Some technologies are truly greater than others, most technologies are incremental varieties that
                  change like the weather. We strive to minimize the amount of dependencies, but use robust libraries to
                  gain leverage. In order to replace a library or framework, we want to see a non-incremental advantage.
                  Think of it as the Amish approach to trendy JS libraries.
                </p>
                <p>
                  When it comes to our primary form of persistence, we like to avoid relational databases when we can.
                  Relational databases are full of remnants of the past that are no longer relevant: Optimizing for Disk
                  IO, minimizing the amount of data redundancy, normalization overhead, mixing business logic with
                  persistence, etc. The reality of ubiquitous cheap memory and cloud infrastructure make NoSQL data
                  stores the engine of choice. RDBMs may still make sense in some specialized circumstances, but with
                  the right practices and supporting data stores we think NoSQL is the way to go.
                </p>
                <p>
                  In reality every data-centric infrastructure will need different data stores that solve different IO
                  patterns and reliability characteristics. We use a mix of Bucket Storage, Elasticsearch, MongoDB and
                  Redis to handle all needs around data persistence and analytics.
                </p>
                <p>
                  In our templates we strive for a balance between “Don’t Repeat Yourself” and the practical speed of
                  copy-and-paste. Too many helper functions and wrappers can create friction, while too much code
                  duplication can limit maintainability.
                </p>
                <br />

                <Header as="h2">Branding Assets</Header>
                <p>You can include our logo into your project to show support and spread the word.</p>
                <Card.Group>
                  <CardStyled href={brandingAssets} header="Download" description="Logo & Icon" />
                </Card.Group>
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Section>
      </LandingWrapper>
    );
  }
}
