import 'theme/semantic.less';

import { Switch, Route, Redirect } from 'react-router-dom';
import React from 'react';
import { hot } from 'react-hot-loader';

import AuthSwitchRoute from 'components/routes/AuthSwitch';
import Protected from 'components/routes/Protected';

import Dashboard from './screens/Dashboard';

import Landing from './screens/Home/Landing';
import Features from './screens/Home/Features';
import Enterprise from './screens/Home/Enterprise';
import Docs from './screens/Home/Docs';
import About from './screens/Home/About';

const App = () => (
  <Switch>
    <AuthSwitchRoute exact path="/" loggedIn={Dashboard} loggedOut={Landing} />
    <Route exact path="/features" component={Features} />
    <Route exact path="/enterprise" component={Enterprise} />
    <Route exact path="/docs" component={Docs} />
    <Route exact path="/about" component={About} />
  </Switch>
);

export default hot(module)(App);
