import React from 'react';
import { Container, Header, Grid, Image, Button, Divider } from 'semantic-ui-react';
import styled from 'styled-components';

import LandingWrapper from 'components/LandingWrapper';

import iconDataCentric from 'assets/icon-data-centric.svg';
import iconHorizontallyScalable from 'assets/icon-horizontally-scalable.svg';
import iconCompliant from 'assets/icon-secure-compliant.svg';
import iconModernStack from 'assets/icon-modern-stack.svg';
import iconSecure from 'assets/icon-secure.svg';
import iconFaultTolerable from 'assets/icon-fault-tolerable.svg';
import screenshotUserManagement from 'assets/screenshots/user-management.png';
import screenshotApiDocumentationPortal from 'assets/screenshots/api-documentation-portal.png';

import capabilitiesWhitepaper from 'assets/downloads/Bedrock Capabilities.pdf';

const Section = styled(Container)`
  &.ui.container {
    padding: 60px 0 20px 0;
    font-size: 16px;

    @media (max-width: 767px) {
      padding: 0 0 40px 0;
    }
  }
`;

const Hero = styled.div`
  padding: 140px 0 80px 0;
  background: #f4f0eb;
  background: linear-gradient(-4deg, #f9f8f5 30%, #f4f0eb 30%);
  margin-bottom: -40px;

  h1.ui.header {
    font-weight: 900;
    margin-top: 0;
  }

  h5.ui.header {
    font-weight: 400;
    text-transform: uppercase;
  }

  .ui.button {
    width: 48px;
    height: 48px;
    padding: 0;
  }

  @media (max-width: 767px) {
    padding: 0 0 80px 0;
    margin-bottom: -80px;

    h1,
    h5 {
      text-align: center;
    }
  }
`;

const FeatureRow = styled(Grid.Row)`
  &.row {
    padding-bottom: 68px !important;
    margin-bottom: 52px;
    border-bottom: 1px solid #ede9e4;

    @media (max-width: 767px) {
      padding-top: 0 !important;
      padding-bottom: 40px !important;
      margin-bottom: 0;

      &:first-child {
        padding-top: 1rem !important;
      }
    }

    &:last-child {
      border-bottom: 0;
    }

    h2 {
      font-weight: 700;
    }

    .ui.image {
      width: 100%;

      @media (max-width: 767px) {
        width: 40%;
        margin: 40px auto;
      }
    }

    &.mobile-reverse {
      @media (max-width: 767px) {
        flex-direction: column-reverse;
      }
    }
  }
`;

export default class Landing extends React.Component {
  render() {
    return (
      <LandingWrapper>
        <Hero>
          <Container>
            <Grid>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column mobile={16} tablet={8} computer={8} verticalAlign="middle">
                <Header as="h5">A Production-Ready Platform</Header>
                <Header as="h1">Features</Header>
              </Grid.Column>
            </Grid>
          </Container>
        </Hero>
        <Section>
          <Grid relaxed="very">
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={5} computer={5} mobile={16} verticalAlign="middle">
                <Image src={iconFaultTolerable} centered />
              </Grid.Column>
              <Grid.Column tablet={8} computer={8} mobile={16}>
                <Header as="h2">Production Infrastructure in 15 Minutes</Header>
                <p>
                  Bedrock.io includes Terraform provisioning and Kubernetes deployment automation. Using a few simple
                  commands you can get a production Kubernetes cluster running that’s fully controlled by you. Unlike
                  SaaS platforms such as Meteor or Heroku, there is no Cloud lock-in and you are in full control.
                  Bedrock.io includes a full backup system, Continuous Integration using Github Actions and playbooks
                  for common ops interventions.
                </p>
              </Grid.Column>
            </FeatureRow>
            <FeatureRow className="mobile-reverse">
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={13} computer={13} mobile={16}>
                <Header as="h2">CRUD UI Perfection</Header>
                <p>
                  The most common UI interaction is that of Create, Read, Update and Delete. Bedrock.io comes with React
                  components and templates that work hand in hand with a rich JSON CRUD API. Building dashboards in
                  particular can be done at great speed thanks to the use of standardized components (Semantic UI). Out
                  of the box screens such as user authentication, forgot password and user management are all included.
                </p>
                <Image src={screenshotUserManagement} centered style={{ width: '70%' }} />
              </Grid.Column>
            </FeatureRow>
            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={6} computer={6} mobile={16} verticalAlign="middle">
                <Image src={screenshotApiDocumentationPortal} centered />
              </Grid.Column>
              <Grid.Column tablet={8} computer={8} mobile={16}>
                <Header as="h2">API-first Approach</Header>
                <p>
                  Having a robust, secure and well documented API forms the backbone of modern platforms. Bedrock.io
                  includes OpenAPI based mechanisms to document API calls. We go a step further than the Swagger based
                  documentation and allow human curation using Github markdown. By default Bedrock.io includes an API
                  documentation portal that allows any developer to easily integrate with your backend.
                </p>
              </Grid.Column>
            </FeatureRow>
            <FeatureRow className="mobile-reverse">
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={8} computer={8} mobile={16}>
                <Header as="h2">Secure by Default</Header>
                <p>
                  Bedrock.io’s dependencies are automatically scanned for security vulnerabilities. The Bedrock Core
                  team regularly assesses any new vulnerabilities and creates artifacts for compliance purposes. The
                  Bedrock team has extensive experience with deploying into regulatory heavy environments (such as FDA
                  CFR Part 11 and HIPAA).
                </p>
                <p>
                  There’s a long list of security best practices and technologies that are included in Bedrock.io. For
                  more information about the security, reliability and scalability features of Bedrock.io. Check out our{' '}
                  <a href={capabilitiesWhitepaper}>Bedrock.io Capabilities Whitepaper</a>.
                </p>
              </Grid.Column>
              <Grid.Column tablet={5} computer={5} mobile={16} verticalAlign="middle">
                <Image src={iconSecure} centered />
              </Grid.Column>
            </FeatureRow>

            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={5} computer={5} mobile={16} verticalAlign="middle">
                <Image src={iconHorizontallyScalable} centered />
              </Grid.Column>
              <Grid.Column tablet={8} computer={8} mobile={16}>
                <Header as="h2">The Un-Framework</Header>
                <p>
                  The technology landscape is constantly changing and it's increasingly difficult to pick the right set
                  of tools. Bedrock's underlying technologies are not set in stone and we will constantly evaluate new
                  libraries and patterns to include into Bedrock Core. That said, we want to avoid unnecessary
                  abstraction layers and instead focus on architecture and interoperability. Data and APIs form the
                  backbone of any modern software solution. Today, our answer to the foundational technologies is
                  Kubernetes, Mongo, Node and React (KMNR). More about our <a href="/about">Philosophy</a>.
                </p>
              </Grid.Column>
            </FeatureRow>

            <FeatureRow>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
              <Grid.Column tablet={12} computer={12} mobile={16}>
                <p></p>
                <Header as="h2">Ready to give Bedrock a spin?</Header>
                <br />
                <p>
                  <a className="ui button primary" href="/docs">
                    Get Started
                  </a>
                </p>
              </Grid.Column>
              <Grid.Column tablet={2} computer={2} mobile={16}></Grid.Column>
            </FeatureRow>
          </Grid>
        </Section>
      </LandingWrapper>
    );
  }
}
